import React, { ReactNode, useEffect } from 'react';
import { Button, Drawer, Space, ConfigProvider, DrawerProps, Form, FormInstance, Modal } from 'antd';
import './ActivityDrawerWrapper.scss';
import intl from 'i18n/intl';
import { PrimaryObjectInfo } from './ActivityDrawerComponents';
import { TActivityRawValue } from 'activities/pages/types/activity.type';
import { pick } from 'lodash';

const language = intl.messages as Record<string, string>;

type TDrawerOptions = DrawerProps & {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  initialValues?: Record<string, TActivityRawValue>;
  primaryObject?: string;
  editable?: boolean;
  loading?: boolean;
  saving?: boolean;
  onSave?: (values: Record<string, string>) => void;
  onClose?: () => void;
};

function DrawerTitle({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <div className="te-flex te-flex-col">
      <span>{title}</span>
      {subtitle && <small style={{ fontSize: 12, fontWeight: 'normal' }}>{subtitle}</small>}
    </div>
  );
}

type TDrawerActions = {
  onDiscard?: () => void;
  onSave?: () => void;
  form: FormInstance;
  saving?: boolean;
};
function DrawerActions({ onDiscard, onSave, saving, form }: TDrawerActions) {
  return (
    <Form form={form}>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => {
          const touched = form.isFieldsTouched();
          return (
            <Space>
              <Button disabled={!touched} onClick={onDiscard} size="small">
                {language.discard_changes}
              </Button>
              <Button disabled={!touched} onClick={onSave} size="small" type="primary" loading={saving}>
                {language.save}
              </Button>
            </Space>
          );
        }}
      </Form.Item>
    </Form>
  );
}

function ActivityDrawerWrapper(props: TDrawerOptions) {
  const { children, subtitle, title, onClose, open, initialValues, primaryObject, editable, loading, saving, onSave } =
    props;
  const [form] = Form.useForm();
  const [modal, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    form.setFieldsValue(initialValues);
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const onSaveValues = async () => {
    if (typeof onSave !== 'function') return;
    try {
      const values = await form.validateFields();
      const fields = Object.keys(values);
      const touchedFields = fields.filter((field) => form.isFieldTouched(field));
      if (touchedFields.length) {
        onSave(pick(values, touchedFields));
      }
    } catch (err) {
      console.log('Error when saving activity', err);
    }
  };

  const checkAndClose = () => {
    const touched = form.isFieldsTouched();
    if (touched) {
      modal.confirm({
        title: language.sure_to_discard_all_changes,
        onOk: () => {
          if (typeof onClose === 'function') {
            onClose();
          }
        },
      });
      return;
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Drawer
      data-testid="ACTIVITY_DRAWER_WRAPPER"
      className="activity-details"
      open={open}
      onClose={checkAndClose}
      destroyOnClose
      title={<DrawerTitle title={title} subtitle={subtitle} />}
      extra={
        editable && (
          <DrawerActions form={form} onDiscard={() => form.resetFields()} onSave={onSaveValues} saving={saving} />
        )
      }
      width={480}
      maskClosable={!editable}
    >
      <ConfigProvider
        theme={{
          components: {
            Form: {
              itemMarginBottom: 4,
            },
          },
        }}
      >
        <Form
          layout="horizontal"
          labelCol={{ span: 8, offset: 0 }}
          labelAlign="left"
          labelWrap
          form={form}
          initialValues={initialValues}
        >
          <PrimaryObjectInfo primaryObject={primaryObject} />
          {children}
        </Form>
      </ConfigProvider>
      {modalContextHolder}
    </Drawer>
  );
}

export default ActivityDrawerWrapper;
