import { getAccessToken } from '@timeedit/te-auth-components/lib/services/token.service';
import { ITEToken } from '@timeedit/types/lib/types/token.type';
import jwtDecode from 'jwt-decode';

export function getAccessTokenRaw() {
  return getAccessToken();
}

export function getAccessTokenDecoded(): ITEToken | null {
  const authToken = getAccessTokenRaw();
  if (authToken) {
    return jwtDecode(authToken);
  }
  return null;
}
