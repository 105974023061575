import { TTEUser } from '@timeedit/types/lib/types';
import { hasPermissions } from '@timeedit/types/lib/utils/permissionCalculator';
import { TPermissionRequirements } from '@timeedit/types/lib/types/permissions.type';

export const getVisibility = (
  environments: string[] | undefined,
  minimumPermissions: TPermissionRequirements = {},
  authUser: TTEUser | undefined = undefined,
) => {
  let isVisible =
    environments?.length && process.env.REACT_APP_TE_APP_ENV
      ? environments.includes(process.env.REACT_APP_TE_APP_ENV)
      : true;
  if (isVisible && (minimumPermissions?.scopes?.length || minimumPermissions?.permissions?.length))
    isVisible = calculatePermissions(authUser, minimumPermissions);
  return isVisible;
};

const calculatePermissions = (authUser: TTEUser | undefined, minimumPermissions: TPermissionRequirements) => {
  if (!authUser) return false;
  return hasPermissions(authUser.appPermissions, authUser.scopes, minimumPermissions);
};
