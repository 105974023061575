/* eslint-disable camelcase */
import { Button, Empty, Modal, Popover, Tooltip, Typography } from 'antd';
import { isEmpty, keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEBaseTable, PathwayCourseDetailsModal, EditableNumberInput } from '@timeedit/ui-components';
import {
  TEInactiveObjectIdsSelector,
  TEObjectMappingSelector,
  TEObjectSelector,
} from '../../../../slices/integration.slice';
import {
  pathwayCoursesTotalSelector,
  pathwaySelectedFormSelector,
  updateCourseExpectedEnrollment,
  updatePathwayCourse,
  updateMultiplePathways,
  coursesForReviewByCourseSelector,
  countPerAllPagesForReviewByCourseSelector,
  reviewByCourseQuerySelector,
  updateReviewByCourseQuery,
  updateReviewByCourseQueryPagination,
  updateReviewByCourseQuerySorting,
  updateReviewByCourseQueryCourseExtIds,
  fetchCoursesForReviewByCourse,
  fetchCoursesCount,
  loadingSelector,
  pathwaysFetchingTrigger,
  programAllCourseRelations,
  pathwayPrimaryObjectsSelector,
  RETURN_EMPTY_COURSE_LIST_BECAUSE_NO_COURSE_SELECTED,
  reviewByCourseFiltersSelector,
} from '../../../slices/pathway.slice';
import { TEObject } from '@timeedit/ui-components/lib/src/types/TEObject.type';
import { TPathway } from '../../PathwayService.type';
import {
  filterObjectsByCategorizedFields,
  getCustomColumnLabelsBasedOnMappingAndForm,
  pathwayIsAcceptedOrRejected,
  pathwayIsFinished,
} from '../../PathwayService.utils';
import PathwayFiltersReviewingByCourse from '../../components/PathwayFiltersReviewingByCourse';
import { EditOutlined, InfoCircleOutlined, InfoOutlined, SwapOutlined, WarningOutlined } from '@ant-design/icons';
import ModalReplaceCourse, { TSubmitReplaceModalPayload } from '../../components/ModalReplaceCourse';
import InactiveCourseWarningIcon from '../../components/InactiveCourseWarningIcon';
import intl, { getInlineString } from '../../../../i18n/intl';
import {
  ReviewByCourseSortableField,
  mongoReactSortOrderMapping,
  reviewByCourseSortableFields,
} from '@timeedit/types/lib/constants';
import PathwayCoursesModal from '../../components/PathwayCoursesModal';
import { renderNumber } from '../../../utils/stringUtils';
import TEObjectLabel from '../../components/TEObjectLabel';
import { usePrevious } from '../../../../hooks/usePrevious';
import ModalEditExpectedEnrollmentOfCourse from 'study-combinations/pages/components/ModalEditExpectedEnrollmentOfCourse/ModalEditExpectedEnrollmentOfCourse';
import { paginationDefaults } from 'constants/table.constants';
import ReviewByCourseFooterActions from 'study-combinations/pages/components/ReviewByCourseFooterActions';

type TPathwayInTable = Partial<TPathway> & {
  _id: string;
  id: string;
  name: string;
  courses: TEObject[];
  status: string;
};
export type TCourseRowData = {
  combinationIds: string;
  expectedEnrollmentDiff?: number;
  expectedEnrollmentOfCourse?: number;
  expectedEnrollmentOfCourseSource: 'pathway-service' | 'TE Server';
  expectedEnrollmentOfStudyCombinations?: number;
  historicalEnrollment?: number;
  id: string;
  key: string;
  name: string;
  te_extid: string;
  _id: string;
  pathways: TPathwayInTable[];
  numberOfStudyCombinations?: number;
};

export type TEditingExpectedEnrollment = Record<string, undefined | number>;

const language = intl.messages;

function ReviewByCourse() {
  const form = useSelector(pathwaySelectedFormSelector);
  const courses = useSelector(TEObjectSelector(form?.pathwaysSettings.course.datasource));
  const programs = useSelector(pathwayPrimaryObjectsSelector);
  const programRelations = useSelector(programAllCourseRelations);
  const pathwayObjects = useSelector(TEObjectSelector(form?.pathwaysSettings.pathways.datasource));
  const isLoading = useSelector(loadingSelector);
  const mapping = useSelector(TEObjectMappingSelector());
  const { labeledFields, fieldLabelsMapping } = mapping;
  const filters = useSelector(reviewByCourseFiltersSelector);
  const { formId } = filters;

  const reviewByCourseQuery = useSelector(reviewByCourseQuerySelector);

  const fetchingTrigger = useSelector(pathwaysFetchingTrigger());
  const prevTrigger = usePrevious(fetchingTrigger);

  const courseTotal = useSelector(pathwayCoursesTotalSelector());
  const dispatch = useDispatch();
  const inactiveCourseIds = useSelector(TEInactiveObjectIdsSelector(form?.pathwaysSettings.course.datasource));

  const coursesForReviewByCourse = useSelector(coursesForReviewByCourseSelector());

  const countPerAllPagesForReviewByCourse = useSelector(countPerAllPagesForReviewByCourseSelector());

  const [selectedCourseToReplace, setSelectedCourseToReplace] = useState<null | TCourseRowData>(null);
  const [selectedPathway, setSelectedPathway] = useState<undefined | Partial<TPathway>>();
  const [selectedCourse, setSelectedCourse] = useState<null | TCourseRowData>(null);
  const [viewingCourse, setViewingCourse] = useState<null | TCourseRowData>(null);

  const coursesFilteredByCategoriesFilter = useMemo(() => {
    const courseDataSource = form?.pathwaysSettings?.course?.datasource || '';
    return filterObjectsByCategorizedFields(courses, filters[courseDataSource]);
  }, [filters, courses, form]);

  const programsFilteredByCategoriesFilter = useMemo(() => {
    const programDataSource = form?.objectScope || '';
    return filterObjectsByCategorizedFields(programs, filters[programDataSource]);
  }, [filters, programs, form]);

  const customColumnLabelsBasedOnMappingAndForm = useMemo(
    () => getCustomColumnLabelsBasedOnMappingAndForm(mapping, form),
    [mapping, form],
  );

  const selectedCoursesInFilter = useMemo(() => {
    if (
      isEmpty(filters.courseObjectReviewingByCourse) &&
      isEmpty(filters.scopedObject) &&
      isEmpty(programsFilteredByCategoriesFilter)
    ) {
      return courses;
    }

    const courseExtIdsInSelectedPrograms: string[] = [];
    const combinedProgramFilters = isEmpty(filters.scopedObject)
      ? programsFilteredByCategoriesFilter
      : filters.scopedObject.filter((extId: string) => programsFilteredByCategoriesFilter?.includes(extId));

    if (combinedProgramFilters?.length > 0) {
      for (const prog of combinedProgramFilters) {
        if (programRelations[prog]) {
          for (const course of programRelations[prog]) {
            if (!courseExtIdsInSelectedPrograms.includes(course)) {
              courseExtIdsInSelectedPrograms.push(course);
            }
          }
        }
      }
    }

    const courseObjectReviewingByCourseArray = filters.courseObjectReviewingByCourse || [];
    return courses.filter((course: TEObject) => {
      if (courseObjectReviewingByCourseArray.length > 0 && courseExtIdsInSelectedPrograms.length === 0) {
        return courseObjectReviewingByCourseArray.includes(course.te_extid);
      }
      if (courseObjectReviewingByCourseArray.length === 0 && courseExtIdsInSelectedPrograms.length > 0) {
        return courseExtIdsInSelectedPrograms.includes(course.te_extid);
      }
      return (
        courseObjectReviewingByCourseArray.includes(course.te_extid) &&
        courseExtIdsInSelectedPrograms.includes(course.te_extid)
      );
    });
  }, [courses, filters, programRelations, programsFilteredByCategoriesFilter]);

  const indexedCourses = useMemo(() => keyBy(courses, 'te_extid'), [courses]);

  const filteredCourseIds = useMemo(() => {
    let filteredCourses = selectedCoursesInFilter.filter((course) => {
      if (coursesFilteredByCategoriesFilter && !coursesFilteredByCategoriesFilter?.includes(course.te_extid))
        return false;
      const validations: Record<string, boolean> = {};
      if (!isEmpty(filters.inactiveCourse)) {
        if (filters.inactiveCourse.includes(true)) {
          validations.inactiveCourse = inactiveCourseIds.includes(course.te_extid);
        }
        if (!validations.inactiveCourse && filters.inactiveCourse.includes(false)) {
          validations.inactiveCourse = !inactiveCourseIds.includes(course.te_extid);
        }
      }
      return Object.keys(validations).every((key) => validations[key]);
    });

    if (filteredCourses.length === 0) {
      filteredCourses = [{ te_extid: RETURN_EMPTY_COURSE_LIST_BECAUSE_NO_COURSE_SELECTED }];
    }

    // If all potential courses are selected, we don't need to filter by course. It could even cause issues in some cases.
    if (filteredCourses.length > 0 && courses.length > 0 && courses.length === filteredCourses.length) {
      return [];
    }

    return filteredCourses.map((course: TEObject) => course.te_extid).sort();
  }, [selectedCoursesInFilter, filters, inactiveCourseIds, coursesFilteredByCategoriesFilter, courses]);

  const [modal, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (filteredCourseIds.length === courses.length && isEmpty(reviewByCourseQuery.courseExtId)) {
      return;
    }
    if (
      filteredCourseIds.length === 1 &&
      filteredCourseIds[0] === RETURN_EMPTY_COURSE_LIST_BECAUSE_NO_COURSE_SELECTED &&
      isEmpty(courses)
    ) {
      return;
    }
    if (JSON.stringify(filteredCourseIds) !== JSON.stringify(reviewByCourseQuery.courseExtId)) {
      dispatch(updateReviewByCourseQueryCourseExtIds(filteredCourseIds));
    }
  }, [filteredCourseIds, dispatch, courses, reviewByCourseQuery]);

  useEffect(() => {
    const output = { ...reviewByCourseQuery };

    output.expectedEnrollmentStudyCombinationsMin =
      typeof filters.expectedEnrollmentOfStudyCombination?.GREATER_THAN === 'number'
        ? filters.expectedEnrollmentOfStudyCombination?.GREATER_THAN
        : undefined;
    output.expectedEnrollmentStudyCombinationsMax =
      typeof filters.expectedEnrollmentOfStudyCombination?.LESS_THAN === 'number'
        ? filters.expectedEnrollmentOfStudyCombination?.LESS_THAN
        : undefined;
    output.expectedEnrollmentOfCourseMin =
      typeof filters.expectedEnrollmentOfCourse?.GREATER_THAN === 'number'
        ? filters.expectedEnrollmentOfCourse?.GREATER_THAN
        : undefined;
    output.expectedEnrollmentOfCourseMax =
      typeof filters.expectedEnrollmentOfCourse?.LESS_THAN === 'number'
        ? filters.expectedEnrollmentOfCourse?.LESS_THAN
        : undefined;
    output.numberOfStudyCombinationsMin =
      typeof filters.noOfStudyCombinations?.GREATER_THAN === 'number'
        ? filters.noOfStudyCombinations?.GREATER_THAN
        : undefined;
    output.numberOfStudyCombinationsMax =
      typeof filters.noOfStudyCombinations?.LESS_THAN === 'number'
        ? filters.noOfStudyCombinations?.LESS_THAN
        : undefined;
    output.expectedEnrollmentDiffMin =
      typeof filters.expectedEnrollmentDiff?.GREATER_THAN === 'number'
        ? filters.expectedEnrollmentDiff?.GREATER_THAN
        : undefined;
    output.expectedEnrollmentDiffMax =
      typeof filters.expectedEnrollmentDiff?.LESS_THAN === 'number'
        ? filters.expectedEnrollmentDiff?.LESS_THAN
        : undefined;

    if (filters.hasCourseError?.length === 1) {
      [output.hasCourseError] = filters.hasCourseError;
    } else {
      output.hasCourseError = undefined;
    }

    if (
      output.expectedEnrollmentStudyCombinationsMin !== reviewByCourseQuery.expectedEnrollmentStudyCombinationsMin ||
      output.expectedEnrollmentStudyCombinationsMax !== reviewByCourseQuery.expectedEnrollmentStudyCombinationsMax ||
      output.expectedEnrollmentOfCourseMin !== reviewByCourseQuery.expectedEnrollmentOfCourseMin ||
      output.expectedEnrollmentOfCourseMax !== reviewByCourseQuery.expectedEnrollmentOfCourseMax ||
      output.numberOfStudyCombinationsMin !== reviewByCourseQuery.numberOfStudyCombinationsMin ||
      output.numberOfStudyCombinationsMax !== reviewByCourseQuery.numberOfStudyCombinationsMax ||
      output.expectedEnrollmentDiffMin !== reviewByCourseQuery.expectedEnrollmentDiffMin ||
      output.expectedEnrollmentDiffMax !== reviewByCourseQuery.expectedEnrollmentDiffMax ||
      output.hasCourseError !== reviewByCourseQuery.hasCourseError
    ) {
      dispatch(updateReviewByCourseQuery({ ...output, page: 1 }));
    }
  }, [
    reviewByCourseQuery,
    dispatch,
    filters.expectedEnrollmentOfStudyCombination?.GREATER_THAN,
    filters.expectedEnrollmentOfStudyCombination?.LESS_THAN,
    filters.expectedEnrollmentOfCourse?.GREATER_THAN,
    filters.expectedEnrollmentOfCourse?.LESS_THAN,
    filters.noOfStudyCombinations?.GREATER_THAN,
    filters.noOfStudyCombinations?.LESS_THAN,
    filters.expectedEnrollmentDiff?.GREATER_THAN,
    filters.expectedEnrollmentDiff?.LESS_THAN,
    filters.hasCourseError,
  ]);

  useEffect(() => {
    if (formId) {
      dispatch(
        fetchCoursesForReviewByCourse({
          ...reviewByCourseQuery,
          pathwayConfigFromUserId: formId,
        }),
      );
    }
  }, [formId, reviewByCourseQuery, dispatch]);

  useEffect(() => {
    if (formId && fetchingTrigger && prevTrigger && fetchingTrigger !== prevTrigger) {
      dispatch(
        fetchCoursesForReviewByCourse({
          ...reviewByCourseQuery,
          pathwayConfigFromUserId: formId,
        }),
      );
    }
  }, [formId, reviewByCourseQuery, dispatch, fetchingTrigger, prevTrigger]);

  useEffect(() => {
    if (formId) {
      dispatch(fetchCoursesCount(formId));
    }
  }, [formId, dispatch]);

  const rowData: TCourseRowData[] = useMemo(() => {
    return (coursesForReviewByCourse || []).map((rowDataItem: TCourseRowData) => {
      const rowDataItemFlatCopy = { ...rowDataItem };
      const { pathways } = rowDataItemFlatCopy;

      let courseError: string = '';

      pathways.forEach((pathway) => {
        if (courseError) return;
        const { electiveCourseError = [], mandatoryCourseError = [] } = pathway;
        [...electiveCourseError, ...mandatoryCourseError].forEach((err) => {
          if (err.scopedObject === rowDataItemFlatCopy.id) {
            courseError = err.errorMessage;
          }
        });
      });

      rowDataItemFlatCopy.expectedEnrollmentOfCourseSource = 'pathway-service';
      if (form?.pathwaysSettings.course.fields?.expectedEnrollment && !rowDataItemFlatCopy.expectedEnrollmentOfCourse) {
        /* With value 0 we will get here but this is on purpse */
        rowDataItemFlatCopy.expectedEnrollmentOfCourse = parseInt(
          indexedCourses[rowDataItemFlatCopy.key]?.[form?.pathwaysSettings.course.fields.expectedEnrollment],
          10,
        );
        rowDataItemFlatCopy.expectedEnrollmentOfCourseSource = 'TE Server';
      }

      if (
        !courseError &&
        (rowDataItemFlatCopy.expectedEnrollmentOfCourse === null ||
          rowDataItemFlatCopy.expectedEnrollmentOfCourse === undefined)
      ) {
        courseError = language.cannot_export_number_of_students as string;
      }

      return {
        ...rowDataItemFlatCopy,
        fieldSettings: {
          pathwayDatasource: form?.pathwaysSettings?.pathways.datasource,
          courseDatasource: form?.pathwaysSettings?.course?.datasource,
        },
        courseError,
      };
    });
  }, [coursesForReviewByCourse, form, indexedCourses]);

  const headerSummary = useMemo(() => {
    if (!form) return [];
    return (
      <>
        {getInlineString(
          'showing_number_of_study_items',
          renderNumber(countPerAllPagesForReviewByCourse, '-'),
          renderNumber(courseTotal, '-'),
          mapping?.objectTypes?.[form?.pathwaysSettings.course.datasource]?.applicationObjectTypeLabel,
        )}
      </>
    );
  }, [form, courseTotal, countPerAllPagesForReviewByCourse, mapping]);

  const fieldSettings = useMemo(
    () => ({
      label: labeledFields?.[form?.pathwaysSettings.course.datasource || ''],
      credits: form?.pathwaysSettings.course.fields?.credits || '',
    }),
    [labeledFields, form],
  );

  const columns = [
    {
      title: customColumnLabelsBasedOnMappingAndForm.courseLabel ?? language.course,
      key: 'name',
      width: 300,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: any) => {
        return (
          <TEObjectLabel datasource={cell.rowData.fieldSettings.pathwayDatasource}>
            <Typography.Text ellipsis>
              <span className="te-flex te-items-center">
                {cell.rowData.name}&nbsp;
                <div className="te-flex te-items-center te-gap-x-2">
                  <InfoCircleOutlined
                    onClick={() => {
                      setViewingCourse({
                        ...cell.rowData,
                        pathways: cell.rowData.pathways?.map((pathway: TPathway) => ({
                          ...pathway,
                          courses: getPathwayCourses(pathway),
                        })),
                      });
                    }}
                    className="te-cursor-pointer"
                  />
                  <InactiveCourseWarningIcon courseId={cell.rowData.te_extid} />
                  {cell.rowData.numberOfStudyCombinations > 0 && (
                    <Button
                      size="small"
                      icon={<SwapOutlined />}
                      onClick={() => setSelectedCourseToReplace(cell.rowData)}
                    />
                  )}
                </div>
              </span>
            </Typography.Text>
          </TEObjectLabel>
        );
      },
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.pathwaysLabel ?? language.study_combinations,
      key: 'combinationIds',
      width: 500,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: any) => {
        return <Typography.Text ellipsis>{cell.rowData.combinationIds}</Typography.Text>;
      },
    },
    {
      title:
        customColumnLabelsBasedOnMappingAndForm.countStudyCombinationsLabel ??
        language.number_of_study_combinations_shorten,
      key: 'numberOfStudyCombinations',
      width: 185,
      resizeable: false,
      sortable: true,
      cellRenderer: (Cell: any) => Cell.rowData.numberOfStudyCombinations,
    },
    {
      title:
        customColumnLabelsBasedOnMappingAndForm.expectedEnrollmentCourseLabel ??
        language.expected_enrollment_course_shorten,
      key: 'expectedEnrollmentOfCourse',
      width: 160,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: any) => {
        return (
          <>
            {
              <EditableNumberInput
                min={1}
                required
                value={
                  !Number.isNaN(cell.rowData.expectedEnrollmentOfCourse) ? cell.rowData.expectedEnrollmentOfCourse : '-'
                }
                onSubmitChange={(val?: number) => onCourseExpEnrollmentChange(cell.rowData.te_extid, Number(val))}
                size="small"
              />
            }
            {cell.rowData.courseError && (
              <Tooltip title={language.course_data_problem as string}>
                <>
                  &nbsp;
                  <WarningOutlined
                    className="text--danger"
                    onClick={() =>
                      modal.error({
                        icon: <WarningOutlined color="error" type="error" />,
                        title: language.course_data_problem as string,
                        content: <div>{cell.rowData.courseError}</div>,
                        onCancel: () => {},
                      })
                    }
                  />
                </>
              </Tooltip>
            )}
            {!cell.rowData.courseError && cell.rowData.expectedEnrollmentOfCourseSource === 'TE Server' && (
              <Tooltip title={language.course_exp_form_te_server as string}>
                <>
                  &nbsp;
                  <InfoCircleOutlined
                    onClick={() =>
                      modal.warning({
                        icon: <InfoCircleOutlined />,
                        title: language.course_exp_form_te_server as string,
                        content: language.course_exp_form_te_server_content as string,
                        onCancel: () => {},
                      })
                    }
                  />
                </>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title:
        customColumnLabelsBasedOnMappingAndForm.expectedEnrollmentStudyCombinationLabel ??
        language.expected_enrollment_study_combination_shorten,
      key: 'expectedEnrollmentOfStudyCombinations',
      width: 180,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: any) => {
        return (
          <span className="te-flex te-items-center">
            {cell.rowData.expectedEnrollmentOfStudyCombinations}
            &nbsp;&nbsp;
            {cell.rowData.numberOfStudyCombinations > 0 && (
              <EditOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedCourse({
                    ...cell.rowData,
                    pathways: cell.rowData.pathways?.map((pathway: TPathway) => ({
                      ...pathway,
                      courses: getPathwayCourses(pathway),
                    })),
                  });
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      title: language.enrolment_diff,
      key: 'expectedEnrollmentDiff',
      width: 180,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: any) => {
        if (!cell.rowData.numberOfStudyCombinations) return '';
        if (cell.rowData.expectedEnrollmentDiff === undefined) return 'N/A';
        if (cell.rowData.expectedEnrollmentDiff === 0) {
          return <b className="text--success">{cell.rowData.expectedEnrollmentDiff}</b>;
        }
        if (cell.rowData.expectedEnrollmentDiff > 0) {
          return <b className="text">+{cell.rowData.expectedEnrollmentDiff}</b>;
        }
        return <b className="text--danger">{cell.rowData.expectedEnrollmentDiff}</b>;
      },
    },
  ];

  const onReplaceCourse = (courseId: string, { action, data }: TSubmitReplaceModalPayload) => {
    if (action === 'replace' && !data.selectedCourse) return;
    dispatch(updatePathwayCourse(courseId, data));
  };

  const onSubmitEditingPathways = (editingExpectedEnrollment: TEditingExpectedEnrollment) => {
    if (!selectedCourse || isEmpty(editingExpectedEnrollment)) return;
    const filteredPathways = selectedCourse.pathways.filter(
      (pathway) => !pathwayIsFinished(pathway.studentSetGenerationStatusString),
    );
    if (isEmpty(filteredPathways)) return;

    const hasChange = filteredPathways.some(
      (pathway: TPathwayInTable) => editingExpectedEnrollment[pathway._id] !== pathway.expectedEnrollment,
    );
    if (!hasChange) return;

    const updatedPathways = filteredPathways.map((pathway) => ({
      ...pathway,
      expectedEnrollment: editingExpectedEnrollment[pathway._id],
      pathwayConfigFromUserId: form?._id,
    }));

    dispatch(updateMultiplePathways(updatedPathways));
  };

  const onCourseExpEnrollmentChange = (courseId: string, courseExpEnrollment: number) => {
    dispatch(
      updateCourseExpectedEnrollment({
        courseId,
        numberOfStudents: courseExpEnrollment,
      }),
    );
  };

  const getPathwayCourses = (pathway: any) => {
    const coursesInPathway = courses.filter((c) =>
      [...(pathway.mandatoryCourseIds || []), ...(pathway.electiveCourseIds || [])].includes(c.te_extid),
    );
    return coursesInPathway;
  };

  return (
    <div className="tabpane__wrapper">
      <PathwayFiltersReviewingByCourse />
      <div className="te-flex te-flex-col te-flex-1" style={{ paddingTop: 20 }}>
        <TEBaseTable.Component
          rowKey="id"
          tableId="STUDY_COMBINATIONS_REVIEWING_BY_COURSE"
          data={rowData}
          loading={isLoading}
          columns={columns}
          emptyRenderer={
            <div className="prefs-p-4 text--disabled">
              <Empty description={language.no_study_combinations_warning as string} />
            </div>
          }
          height={rowData?.length ? undefined : 250}
          draggable={false}
          footerLeftActions={
            <ReviewByCourseFooterActions
              reviewByCourseQuery={reviewByCourseQuery}
              courseLength={rowData.length}
              formId={formId}
            />
          }
          columnsEditable
          selectedRowKeys={[]}
          headerSummary={headerSummary}
          onColumnSortChange={(order, key) => {
            if (
              typeof key === 'undefined' ||
              (typeof key === 'string' && reviewByCourseSortableFields.includes(key as ReviewByCourseSortableField))
            ) {
              dispatch(
                updateReviewByCourseQuerySorting({
                  sortBy: key as ReviewByCourseSortableField, // This assertion is save because of the previous if condition
                  sortDirection: mongoReactSortOrderMapping[order],
                }),
              );
            } else {
              console.error(
                `Review by course table got wrong sort key: ${key}, must be one of ${JSON.stringify(
                  reviewByCourseSortableFields,
                )}`,
              );
            }
          }}
          deactivateComponentSortingForKeys={[
            'name',
            'combinationIds',
            'numberOfStudyCombinations',
            'expectedEnrollmentOfCourse',
            'expectedEnrollmentOfStudyCombinations',
            'expectedEnrollmentDiff',
          ]}
          enablePagination={courseTotal > rowData.length}
          onPageOrPageSizeChange={(current: number, pageSize: number) => {
            dispatch(
              updateReviewByCourseQueryPagination({
                page: current || 1 /* Sometimes values come in as "0". But we cannot have that. So, pass 1 instead */,
                pageSize,
              }),
            );
          }}
          defaultPageSize={paginationDefaults.reviewStudyCombinationsPerPage}
          countPerAllPages={countPerAllPagesForReviewByCourse}
          currentPage={reviewByCourseQuery.page}
        />
      </div>
      <PathwayCourseDetailsModal
        fieldSettings={fieldSettings}
        open={!!viewingCourse}
        course={indexedCourses[viewingCourse?.te_extid as string] || {}}
        pathways={viewingCourse?.pathways || []}
        labeledFieldsMapping={fieldLabelsMapping?.[form?.pathwaysSettings.course.datasource || ''] || {}}
        onCancel={() => setViewingCourse(null)}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText={language.close as string}
        okButtonProps={{
          type: 'default',
        }}
        onOk={() => {
          setViewingCourse(null);
        }}
        onSelectPathway={(pathway: Partial<TPathway>) => {
          const pathwayCopy = { ...pathway };
          if (!pathwayCopy.rawCourses) {
            pathwayCopy.rawCourses = getPathwayCourses(pathwayCopy);
          }
          setSelectedPathway(pathwayCopy);
        }}
        additionalPathwayFieldsRenderer={(pathway: TPathway) => {
          const isDisabled = pathwayIsAcceptedOrRejected(pathway.reviewStatus);
          return (
            <div style={{ flexBasis: '100%' }}>
              <div
                className="te-text-xs"
                style={{
                  display: 'inline-grid',
                  gridTemplateColumns: 'auto 80px',
                  gap: '0 12px',
                }}
              >
                <span className="">{language.historical_enrollment as string}:</span>
                <span>
                  {[null, undefined, ''].includes(pathway.historicalEnrollment) ? '-' : pathway.historicalEnrollment}
                </span>
                <span className="">{language.expected_enrollment as string}: </span>
                <span className="te-flex te-items-center">
                  {pathway.expectedEnrollment}
                  {isDisabled && (
                    <>
                      &nbsp;
                      <Popover content={language.study_combination_is_accepted_or_rejected as string}>
                        <WarningOutlined className="text--danger" />
                      </Popover>
                    </>
                  )}
                </span>
              </div>
              {form?.pathwaysSettings?.course?.optionalFields?.map((field) => {
                return (
                  <div key={field.object} className={'te-flex'}>
                    <span className="">
                      {fieldLabelsMapping[form.pathwaysSettings?.course.datasource][field.object]}:{' '}
                    </span>
                    <span className={'te-flex te-items-center'}>
                      {indexedCourses[viewingCourse?.te_extid as string]
                        ? indexedCourses[viewingCourse?.te_extid as string][field.object]
                        : ''}
                    </span>
                  </div>
                );
              })}
              {form?.pathwaysSettings?.pathways?.optionalFields.map((field) => {
                const suitablePrograms = programRelations[pathway.programExtId as string];
                const suitablePathways = pathwayObjects.filter((p) => suitablePrograms.includes(p.te_extid));
                const selectedPathwayObject = suitablePathways.find((obj) => Object.values(obj).includes(pathway.name));
                return (
                  <div key={field.object} className={'te-flex'}>
                    <span>{fieldLabelsMapping[form.pathwaysSettings?.pathways.datasource][field.object]}: </span>
                    <span className={'te-flex te-items-center'}>
                      {selectedPathwayObject ? selectedPathwayObject[field.object] : ''}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
      <PathwayCoursesModal
        form={form}
        pathway={selectedPathway}
        title={selectedPathway?.name}
        courses={selectedPathway?.rawCourses || []}
        open={!!selectedPathway}
        onClose={() => setSelectedPathway(undefined)}
      />

      <ModalEditExpectedEnrollmentOfCourse
        open={!!selectedCourse}
        pathways={selectedCourse?.pathways}
        onCancel={() => setSelectedCourse(null)}
        onOk={(editingExpectedEnrollment: TEditingExpectedEnrollment) => {
          setSelectedCourse(null);
          onSubmitEditingPathways(editingExpectedEnrollment);
        }}
        fieldSettings={fieldSettings}
        onSelectPathway={(pathway: Partial<TPathway>) => {
          const pathwayCopy = { ...pathway };
          if (!pathwayCopy.rawCourses) {
            pathwayCopy.rawCourses = getPathwayCourses(pathwayCopy);
          }
          setSelectedPathway(pathwayCopy);
        }}
      />

      <ModalReplaceCourse
        course={selectedCourseToReplace}
        onCancel={() => setSelectedCourseToReplace(null)}
        courses={courses}
        courseLabelField={labeledFields?.[form?.pathwaysSettings.course?.datasource || '']}
        courseDatasourceField={form?.pathwaysSettings.course?.datasource || ''}
        datasource={form?.pathwaysSettings.course.datasource}
        onSubmit={({ action, data }) => {
          if (selectedCourseToReplace?.te_extid) {
            onReplaceCourse(selectedCourseToReplace?.te_extid, {
              action,
              data,
            });
          }
          setSelectedCourseToReplace(null);
        }}
      />
      {modalContextHolder}
    </div>
  );
}

export default ReviewByCourse;
