import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useImportActivitiesModal } from './ImportActivitiesModal.context';
import { TABS } from './ImportActivitiesModal.constants';
import intl from 'i18n/intl';

const language = intl.messages;

export default function ImportActivitiesModalFooter() {
  const { file, tab, setTab, onClose, onReset, onImport, importing, settings, importResult, onFinish } =
    useImportActivitiesModal();

  const actionButtons = useMemo(() => {
    switch (tab) {
      case TABS[0].key: {
        return (
          <Button size="small" onClick={() => setTab(TABS[1].key)} disabled={!file || !settings?.templateId}>
            {language.next as string}
          </Button>
        );
      }
      case TABS[1].key:
        return (
          <>
            <Button size="small" onClick={onReset}>
              {language.back as string}
            </Button>
            <Button loading={importing} type="primary" size="small" onClick={() => onImport()}>
              {language.import as string}
            </Button>
          </>
        );
      case TABS[2].key:
        return (
          <>
            <Button onClick={onReset} size="small" disabled={importing}>
              {language['activities.import.actions.upload_new_file'] as string}
            </Button>
            <Button
              onClick={() => {
                onFinish();
              }}
              loading={importing}
              disabled={importResult?.status !== 'success'}
              type="primary"
              size="small"
            >
              {language['activities.import.actions.add_to_activities_overview'] as string}
            </Button>
          </>
        );
      default:
        return null;
    }
  }, [settings, file, tab, setTab, importing, onImport, onReset, onFinish, importResult]);
  return (
    <div className="te-flex te-pt-2" data-testid="IMPORT_ACTIVITIES_MODAL_FOOTER">
      <Button
        size="small"
        onClick={() => {
          onClose();
        }}
      >
        {language.cancel as string}
      </Button>
      <span className="te-flex-1" />
      {actionButtons}
    </div>
  );
}
