import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import WeeksSelector from './WeeksSelector';
import { castArray } from 'lodash';

type TWeeksSelectorModalProps = {
  value: number | number[];
  allowMultiple?: boolean;
  onSubmit: (value: number[]) => void;
  editable?: boolean;
};
export default function WeeksSelectorModal(props: TWeeksSelectorModalProps) {
  const { value, allowMultiple, onSubmit, editable } = props;

  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState<number[]>(castArray(value));

  useEffect(() => {
    setLocalValue(castArray(value));
  }, [value]);

  return (
    <>
      {editable && (
        <Button size="small" onClick={() => setOpen(true)}>
          Edit
        </Button>
      )}

      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        title="Edit weeks"
        width={656}
        onOk={() => {
          onSubmit(localValue);
          setOpen(false);
        }}
      >
        <WeeksSelector value={localValue} onChange={setLocalValue} allowMultiple={allowMultiple} />
      </Modal>
    </>
  );
}
