import React from 'react';
import TEObjectsService from 'activities/services/TEObjects.service';
import { castArray } from 'lodash';
import { SelectProps } from 'antd';

type TDatasourceSelectorV3 = {
  typeId?: number;
  typeExtId?: string;
  value: string | string[];
  fieldExtId?: string;
  onChange: (newValue: string | string[]) => void;
  name?: string;
  allowMultiple?: boolean;
  size?: SelectProps['size'];
};
export default function DatasourceSelectorV3({ value }: TDatasourceSelectorV3) {
  return <div>{castArray(value).map((val) => TEObjectsService.getObjectLabel(val))}</div>;
}
