/* eslint-disable import/no-extraneous-dependencies */
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import XLSX from 'xlsx';

export const toDurationDisplay = (minutes: number): string => {
  return moment().startOf('date').add(minutes, 'minute').format('HH:mm');
};

export const toCenterOfWeek = (week: number | string | Dayjs) => {
  const momentWeek = dayjs(week);
  return dayjs((momentWeek.startOf('week').valueOf() + momentWeek.endOf('week').valueOf()) / 2);
};

export const excelToJSON = (file: File): Record<string, any> => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  return new Promise((resolve) => {
    reader.onload = async (e: any) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const parsedSheets = Object.keys(wb.Sheets).reduce((results, sheetKey) => {
        return {
          ...results,
          [sheetKey]: XLSX.utils.sheet_to_json(wb.Sheets[sheetKey], {
            defval: '',
            blankrows: true,
          }),
        };
      }, {});
      resolve(parsedSheets);
    };
  });
};
