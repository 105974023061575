import React, { ReactNode, useMemo } from 'react';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { Divider, Form, Input, Skeleton, Tag, Tooltip } from 'antd';
import TEFormItem from '../BaseElements/TEFormItem';
import Duration from '../BaseElements/Duration';
import TEObjectsService from 'activities/services/TEObjects.service';
import DatasourceSelectorV3 from 'components/DatasourceSelectorV3';
import ActivityValueCell from '../Table/ActivityValueCell';
import intl from 'i18n/intl';
import WeeksSelectorModal from '../WeeksSelector/WeeksSelectorModal';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

const language = intl.messages as Record<string, string>;

type TActivityValuesProps = {
  track?: number;
  values?: TActivity2Be['values'];
  loading?: boolean;
  title?: string;
  editable?: boolean;
  multipleValuesExtIds?: string[];
  children?: ReactNode;
  formId?: null | string;
};

function ActivityFieldValue({ extId }: TActivity2Be['metadata']['field'][number]) {
  return (
    <Form.Item label={TEObjectsService.getFieldLabel(extId)} name={extId}>
      <Input size="small" />
    </Form.Item>
  );
}

function ActivityObjectValue({ extId, type }: TActivity2Be['metadata']['object'][number]) {
  if (type) {
    return (
      <Form.Item name={extId} label={TEObjectsService.getFieldLabel(extId)}>
        <TEFormItem name={extId}>
          {({ value, onChange }) => (
            <DatasourceSelectorV3
              fieldExtId={extId}
              allowMultiple
              value={value as string[]}
              onChange={(value: string | string[]) => onChange(value)}
              typeExtId={type}
              size="small"
            />
          )}
        </TEFormItem>
      </Form.Item>
    );
  }

  return (
    <Form.Item name={extId} label={TEObjectsService.getObjectTypeLabel(extId)}>
      <TEFormItem name={extId}>
        {({ value, onChange }) => (
          <DatasourceSelectorV3
            allowMultiple={false}
            value={value as string[]}
            onChange={(value: string | string[]) => onChange(value)}
            typeExtId={extId}
            size="small"
          />
        )}
      </TEFormItem>
    </Form.Item>
  );
}

export function ActivityWeeks({ editable, allowMultiple }: { editable?: boolean; allowMultiple?: boolean }) {
  const form = Form.useFormInstance();

  const weeks = Form.useWatch('weeks', form) || [];

  const formattedWeeks = useMemo(() => {
    try {
      if (Array.isArray(weeks)) return weeks;
      return JSON.parse(weeks);
    } catch {
      return weeks;
    }
  }, [weeks]);

  const weeksDisplay = useMemo(() => {
    if (!formattedWeeks) return '';

    const sorted = sortBy(formattedWeeks.map((week: number) => dayjs(week).isoWeek()));
    const chunks: number[][] = [];

    sorted.forEach((week) => {
      const prevChunk = chunks[chunks.length - 1];
      if (!prevChunk) {
        chunks.push([week]);
      } else if (week - prevChunk[prevChunk.length - 1] === 1) {
        prevChunk.push(week);
      } else {
        chunks.push([week]);
      }
    });

    return (
      <>
        {chunks
          .map((chunk) => {
            if (chunk.length === 1) return chunk[0];
            return [chunk[0], chunk[chunk.length - 1]].join('-');
          })
          .join(', ')}
        &nbsp;
        {formattedWeeks.length > 1 ? `(x${formattedWeeks.length})` : ''}
        <TEFormItem name="weeks">
          {({ value, onChange }) =>
            editable && (
              <WeeksSelectorModal
                allowMultiple={allowMultiple}
                value={value as unknown as number[]}
                onSubmit={(weeks) => {
                  onChange(weeks);
                }}
                editable={editable}
              />
            )
          }
        </TEFormItem>
      </>
    );
  }, [formattedWeeks, editable]);

  const weekLabel = useMemo(() => {
    if (formattedWeeks?.length === 1) return language.week;
    return language.weeks;
  }, [formattedWeeks?.length]);

  return (
    <Form.Item name={editable && 'dateRanges'} data-testid="ACTIVITY_WEEKS" label={weekLabel} tooltip={weekLabel}>
      {weeksDisplay}
    </Form.Item>
  );
}

export function ActivityTracks({ track }: { track?: number }) {
  if (track === undefined) return null;
  return (
    <Form.Item label={language.track} tooltip={language.track}>
      #{track}
    </Form.Item>
  );
}

function ActivityValues(props: TActivityValuesProps) {
  const { loading, title, values, editable, children, multipleValuesExtIds, formId } = props;
  return (
    <div data-testid="ACTIVITY_VALUES_IN_DRAWER">
      <Divider orientation="left">{title}</Divider>

      <Skeleton loading={loading}>
        {children}

        <Form.Item label={language.duration} tooltip={language.duration}>
          <TEFormItem
            name="duration"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error())),
              },
            ]}
            type="number"
          >
            {({ value, onChange }) => <Duration value={value as number} onChange={onChange} disabled={!editable} />}
          </TEFormItem>
        </Form.Item>

        {values?.map((val) => {
          if (!editable) {
            return (
              <Form.Item
                label={TEObjectsService.getObjectTypeLabel(val.extId, TEObjectsService.getFieldLabel(val.extId))}
                key={val.extId}
              >
                {multipleValuesExtIds?.includes(val.extId) ? (
                  <Tooltip title={language.activity_has_multiple_values}>
                    <i>
                      <ActivityValueCell activityValue={val.value} />
                    </i>
                  </Tooltip>
                ) : (
                  <ActivityValueCell activityValue={val.value} />
                )}
              </Form.Item>
            );
          }

          switch (val.type) {
            case 'object': {
              if ((val.value as any).categories) return 'Categories';
              return <ActivityObjectValue editable extId={val.extId} />;
            }
            case 'field':
              return <ActivityFieldValue extId={val.extId} />;
            default:
              return <ActivityFieldValue extId={val.extId} />;
          }
        })}
      </Skeleton>
    </div>
  );
}

export default ActivityValues;
