import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import wsClient from './services/socket.service';
import React, { useEffect } from 'react';
import { createOrganizationContextFromToken } from '@timeedit/types/lib/types/launchDarkly.type';

// AUTH
import { AuthWrapper } from '@timeedit/te-auth-components/lib';
import { DEBUG_LEVEL } from '@timeedit/te-auth-components/lib/components/AuthLoadingWrapper/AuthLoadingWrapper';
import { ITEToken, ITETokenInfo } from '@timeedit/types/lib/types';

// ENUMS
import { EAppId } from '@timeedit/types/lib/enums';

// COMPONENTS
import { ThemeWrapper } from '@timeedit/ui-components';
import { defaultTheme } from '@timeedit/ui-components/lib/src/assets/theme/default';

// ROUTE

// SLICES
import { onLoginSuccess, onLogout, onRetryWebsocketConnection } from './slices/auth.slice';

// STYLES
import './App.scss';

// CONFIGS
import { configService } from './services/config.service';

// UTILS
import { fetchOrganization } from './slices/organization.slice';
import DataManager from './pages/DataManager';
import { useLDClient } from 'launchdarkly-react-client-sdk';

notification.config({
  placement: 'bottomLeft',
  duration: 3,
});

function App() {
  const ldClient = useLDClient();
  const dispatch = useDispatch();
  const authUser = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    if (authUser) {
      wsClient.init(() => {
        dispatch(onRetryWebsocketConnection());
      });
      dispatch(fetchOrganization());
    }
  }, [authUser, dispatch]);

  /**
   * EVENT HANDLERS
   */
  const setLoginToRedux = (userToken: ITEToken) => {
    try {
      const ldIdentity = createOrganizationContextFromToken(userToken);
      ldClient?.identify(ldIdentity);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to identify LD: ', err);
    }
    dispatch(onLoginSuccess(userToken));
  };

  const logoutFromRedux = () => {
    ldClient?.close().catch((err) =>
      // eslint-disable-next-line no-console
      console.error('Failed to close LD: ', err),
    );
    dispatch(onLogout());
  };

  const urls: ITETokenInfo = {
    BASE_URL: configService().REACT_APP_BASE_URL,
    AUTH_URL: configService().REACT_APP_AUTH_DOMAIN,
  };

  return (
    <ThemeWrapper theme={defaultTheme}>
      <AuthWrapper
        appId={EAppId.TE_DATA_MANAGER}
        urls={urls}
        renderChildrenWhileLoading={false}
        onLoginSuccess={setLoginToRedux}
        onLogout={logoutFromRedux}
        debugLevel={process.env.NODE_ENV === 'development' ? DEBUG_LEVEL.EXCESSIVE : DEBUG_LEVEL.NONE}
      >
        <DataManager />
      </AuthWrapper>
    </ThemeWrapper>
  );
}

export default App;
